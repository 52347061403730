<template>
  <v-app>
    <v-app-bar
        app
        flat
        fixed
    >
      <v-spacer/>
      <v-toolbar-items class="mr-10">
        <v-btn
            text
            :to="item.to"
            v-text="item.title"
            v-for="(item, index) in menu"
            :key="index"
        />
        <v-spacer/>
      </v-toolbar-items>

      <template v-if="isLoggedIn">
        <v-btn
            outlined
            color="primary"
            to="/dashboard"
        >
          Dashboard
        </v-btn>
        <v-btn
            class="ml-3"
            text
            @click.prevent="logout"
        >
          Logout
        </v-btn>
      </template>
      <template v-else>
        <v-btn
            outlined
            color="primary"
            class="mr-3"
            to="/account/login"
        >
          Login
        </v-btn>
        <v-btn
            text
            to="/account/register"
        >
          Register
        </v-btn>
      </template>

    </v-app-bar>
    <v-main>
      <router-view/>
    </v-main>

  </v-app>
</template>

<script>
import TokensService from "@/services/TokensService";

export default {
  name: 'IndexLayout',

  data() {
    return {
      menu: [
        {
          title: 'Home',
          to: '#home'
        },
        {
          title: 'Who we are',
          to: '#who-we-are'
        },
        {
          title: 'Parcel tracking',
          to: '#parcel-tracking'
        },
        {
          title: 'Contact us',
          to: '#contact-us'
        }
      ]
    }
  },

  computed: {
    isLoggedIn: () => {
      return !!TokensService.getRefreshToken()
    }
  },

  mounted() {
    if (this.isLoggedIn) {
      this.$router.push('dashboard')
    }
  },

  methods: {
    logout: function () {
      TokensService.clear()
      this.$router.push('/')
    }
  }
}
</script>